<template>
	<div class="search">
		<van-nav-bar left-arrow title="查询案件" @click-left="onClickLeft">
		</van-nav-bar>
		<!-- <div class="search-bar"> -->
		<van-search
			background="#ffffff"
			clearable
			v-model="filter.keyLike"
			placeholder="可通过姓名、手机号、车牌号、业务编号进行查询"
			@search="onSearch"
		/>

		<!-- <span>
				{{ searchStaffName || "家访人员" }}
			</span> -->
		<!-- </div> -->

		<div class="history" v-if="!searched">
			<div class="head">
				<div class="l">查询历史</div>
				<div class="r" v-if="searchHistory.length" @click="clearHistory">
					清除历史记录
				</div>
			</div>
			<div class="items" v-if="searchHistory.length">
				<div
					class="item"
					v-for="(item, index) in searchHistory"
					:key="index"
					@click="onSearch(item)"
				>
					<span>{{ item }}</span>
				</div>
			</div>
			<div v-else>
				<van-empty description="暂无搜索记录" />
			</div>
		</div>

		<van-pull-refresh v-if="searched" v-model="refreshing" @refresh="onRefresh">
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
				class="list-box"
			>
				<div class="list-item" v-for="(item, index) in list" :key="index">
					<van-checkbox
						v-if="filter.orderStatus != '2'"
						class="checkbox"
						v-model="item.checked"
						@change="onItemChange"
						shape="round"
					/>
					<CaseCard
						:item="item"
						:orderStatus="filter.orderStatus"
						:key="index"
						class="item"
						@actionClick="onActionClick"
					>
					</CaseCard>
				</div>
			</van-list>
		</van-pull-refresh>
		<SubmitBar
			v-if="searched && filter.orderStatus != '2'"
			:title="filter.orderStatus == '0' ? '派单' : '改派'"
			:checked="checkedAll"
			@change="onChange"
			@submit="onSubmit"
		></SubmitBar>

		<PopupForm
			v-model="showPopup"
			:actionType="actionType"
			:actionItem="actionItem"
			@confirmPopup="confirmPopup"
		>
		</PopupForm>
		<!-- <PopupPicker
			v-model="pickerView"
			:columns="pickerColumns"
			useSearch
			@confirm="pickerConfirm"
		/> -->
	</div>
</template>
<script>
import * as _ from "lodash";
import Vue from "vue";
import { Search, Empty, Tabbar, TabbarItem, Toast } from "vant";
import popupFormMixin from "@/mixins/popupFormMixin";

Vue.use(Empty);
Vue.use(Search);
Vue.use(Tabbar);
Vue.use(TabbarItem);

import Api from "@/api/caseHandle";

const HISTORY_KEY = "search-case-history";
// const orderStatusEnum = {
// 	"0": "ACCEPTING",
// 	"1": "ACCEPTING,PROCESSING",
// 	"2": "ACCEPTING"
// };
const orderStatusEnum = {
	"0": ["ASSIGNING"],
	"1": ["ACCEPTING", "PROCESSING"],
	"2": ["ACCEPTING"]
};

export default {
	mixins: [popupFormMixin],
	components: {
		SubmitBar: () => import("@/views/List/components/SubmitBar")
	},
	data() {
		return {
			filter: {
				keyLike: "",
				candidatEq: "",
				orderStatus: this.$route.query.orderStatus || "0"
			},
			searchHistory: [],
			searchStaffNo: "",
			searchStaffName: "",
			list: [],
			loading: false,
			finished: false,
			checkedAll: false,
			refreshing: false,
			pageNo: 1,
			pageSize: 10,
			searched: false
		};
	},
	methods: {
		batchProcessing() {
			let checkedHomeNos = this.list
				.filter((item) => item.checked)
				.map((item) => item.homeNo);
			if (this.actionType == "批量改派") {
				this.changeClick({}, checkedHomeNos);
			} else {
				this.distributeClick({}, checkedHomeNos);
			}
		},
		onChange(v) {
			this.list.forEach((element) => {
				element.checked = v;
			});
			this.checkedAll = v;
		},
		onItemChange() {
			let checked = this.list.filter((item) => item.checked);
			if (checked.length <= 0) {
				this.checkedAll = false;
			}
			if (checked.length == this.list.length) {
				this.checkedAll = true;
			}
		},
		onSubmit() {
			let checked = this.list.filter((item) => item.checked);
			if (checked.length <= 0) {
				return Toast.fail("请选择要处理的数据");
			}
			this.actionItem = {};
			this.actionType =
				this.filter.orderStatus == "0" ? "批量派单" : "批量改派";
			this.showPopup = true;
		},
		clearHistory() {
			this.searchHistory = [];
			this.writeHistory();
		},
		readHistory() {
			try {
				this.searchHistory =
					JSON.parse(localStorage.getItem(HISTORY_KEY)) || [];
			} catch (error) {
				this.searchHistory = [];
			}
		},
		writeHistory() {
			localStorage.setItem(HISTORY_KEY, JSON.stringify(this.searchHistory));
		},

		// 搜索
		async onSearch(keyLike) {
			const { searchHistory, filter } = this;
			filter.keyLike = keyLike;
			if (searchHistory.indexOf(keyLike) < 0 && _.trim(keyLike) != "") {
				searchHistory.splice(0, 0, keyLike);
				this.writeHistory();
			}

			this.searched = true;
			this.pageNo = 1;
			this.list = [];
			this.onLoad();
		},

		onSerachClick() {
			this.$router.push({
				name: "searchCase"
			});
		},
		async onLoad() {
			try {
				this.finished = false;
				this.loading = true;
				const { pageNo, pageSize, filter } = this;
				// 去除空项
				for (const key in filter) {
					if (filter[key] === "" || filter[key] === null) {
						delete filter[key];
					}
				}
				var res = await Api.findPageISO({
					pageNo,
					pageSize,
					...filter,
					// statusEq: orderStatusEnum[filter.orderStatus]
					statusIn: orderStatusEnum[filter.orderStatus]
				});
				const { data } = res;
				const { list } = data;
				list.forEach((item) => {
					item.checked = false;
				});
				if (this.pageNo == 1) {
					this.list = list;
				} else {
					this.list = [...this.list, ...list];
				}
				this.finished = !data.isNextPage;
				this.pageNo++;
			} catch (error) {
				Toast.fail(error);
				this.list = [];
				this.finished = true;
			} finally {
				this.loading = false;
				this.refreshing = false;
			}
		},
		onRefresh() {
			this.pageNo = 1;
			this.checkedAll = false;
			this.onLoad();
		}
	},
	mounted() {
		this.readHistory();
	}
};
</script>
<style lang="less" scoped>
.search {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;

	.van-search {
		/deep/ input {
			&::placeholder {
				font-size: 12px;
			}
		}
	}

	.history {
		padding: 10px 0px;

		.head {
			display: flex;
			justify-content: space-between;
			padding: 0px 10px;

			.r {
				color: #3c86ff;
			}
		}

		.items {
			display: flex;
			flex-wrap: wrap;
			padding: 10px 0px 0px 0px;

			.item {
				padding: 5px 10px;

				span {
					display: inline-block;
					padding: 0.8em 1em;
					background: #fff;
				}
			}
		}
	}
}

.list-box {
	padding: 10px 12px;
	padding-bottom: 70px;
	.list-item {
		position: relative;
		.checkbox {
			position: absolute;
			right: 17px;
			top: 18px;
			z-index: 3;
			/deep/ .van-checkbox__icon .van-icon {
				border-width: 2px;
			}
		}
	}
	.item {
		margin-bottom: 15px;
	}
}
</style>
